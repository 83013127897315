import { useEffect } from 'react'
import Util from '../../utils';
function Loading() {
  const loadArr = [
    '/images/p_card_1.webp',
    '/images/p_card_2.webp',
    '/images/p_card_3.webp',
    '/images/p_card_4.webp',
    '/images/p_card_5.webp',
    '/images/p_save_title_1.webp',
    '/images/p_save_title_2.webp',
    '/images/p_save_text_1.webp',
    '/images/p_save_text_2.webp',
    '/images/p_bg.png',
    '/images/p_mask.png'
  ]
  
    useEffect(()=>{
      for(let item of loadArr){
        Util.loadImage(item)
      }
    },[])
    return (
      <></>
    );
  }
  
  export default Loading;